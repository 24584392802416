import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import axios from "axios";
import ElementUI from "element-ui";
import vuetify from "@/plugins/vuetify";
import locale from "element-ui/lib/locale/lang/en";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vueDebounce from "vue-debounce";
import VueLazyload from "vue-lazyload";
import "@/filter";
import VueCookies from "vue-cookies";
import Paginate from "vuejs-paginate";
import numeral from "numeral";
import numFormat from "vue-filter-number-format";
import enLang from "@/constant/lang/en";
import Select2 from "v-select2-component";
import linkify from "vue-linkify";
import CKEditor from "ckeditor4-vue";
import SortHeader from "@/components/SortHeader";
import VueWaypoint from "vue-waypoint";
import context from "@/_context/context";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/styles.scss";
Vue.use(CKEditor);
Vue.filter("numFormat", numFormat(numeral));
Vue.use(VueCookies);
Vue.use(context);
Vue.use(enLang);
Vue.use(ElementUI, { locale });
Vue.use(vueDebounce);
Vue.use(VueWaypoint);
Vue.component("VuePerfectScrollbar", VuePerfectScrollbar);
Vue.component("paginate", Paginate);
Vue.component("select2", Select2);
Vue.component("sortHeader", SortHeader);
Vue.directive("linkified", linkify);
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "/static/image/irender-placeholder.svg",
  loading: "/static/image/irender-placeholder.svg",
  attempt: 1,
});

var controller = new AbortController();
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
axios.interceptors.request.use(
  function (config) {
    //config.headers.Authorization = ``;
    //config.signal = controller.signal;
    let source = axios.CancelToken.source();
    config.cancelToken = source.token;
    store.commit("ADD_CANCEL_TOKEN", source);
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    //console.log("axios.interceptors", error);
    //if (error && ((error.response && error.response.status && error.response.status === 401))) {
    //    window.location = '/login';
    //}
    return error;
  }
);

String.prototype.format = function () {
  var a = this;
  for (var k in arguments) {
    a = a.replace(new RegExp("\\{" + k + "\\}", "g"), arguments[k]);
  }
  return a;
};
Vue.prototype.$http = axios;
Vue.config.productionTip = false;
Vue.prototype.axiosController = controller;

var vm = new Vue({
  router,
  store,
  vuetify,
  data() {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      globalLoading: false,
      $userDetailInfo: null,
      userDetailInfo: null,
      $controller: controller,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize();
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      Vue.set(this, "windowHeight", window.innerHeight);
      Vue.set(this, "windowWidth", window.innerWidth);
    },
  },
  render: (h) => h(App),
}).$mount("#app");
