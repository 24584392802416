import { checkAccessFunction } from '@/utils'
import store from '@/store/index'
const signalR = require('@microsoft/signalr')
const apiHost = process.env.VUE_APP_API_HOST
const $signalRCon = new signalR.HubConnectionBuilder().withUrl(`${apiHost}signalr/ZStorageCleaningHub`).build()

$signalRCon
    .start()
    .then(() => {
        console.log('connected signalR', process.env.VUE_APP_API_HOST)
    })
    .catch((error) => console.error(error))

$signalRCon.onreconnecting(() => {
    console.log('Connection closed. Retrying...')
})
$signalRCon.onreconnected(() => {
    console.log('reconnected signalR', process.env.VUE_APP_API_HOST)
})

export default {
    install(Vue) {
        Vue.prototype.$signalR = $signalRCon
        Vue.prototype.$intersectionOptions = {
            root: null,
            rootMargin: '0px 0px 0px 0px',
            threshold: [0, 1] // [0.25, 0.75] if you want a 25% offset!
        }
        Vue.prototype.$isUndefinedOrNull = (value) => {
            return typeof value === 'undefined' || value === null
        }
        Vue.prototype.$checkAccess = (functionCode) => { 
            return checkAccessFunction(store.getters['auth/decentralization'], functionCode)
        }
        Vue.prototype.$none = () => {}
        Vue.prototype.$testUserEmail = [
            "duyhh@irender.vn",
            "dungnt@irender.vn",
            "quynhnt@irender.vn",
        ]
    }
}
